import { Container } from '@loft/react-loft';
import { color } from '@loft/tokens-loft';
import React from 'react';
import { ContentBlock } from './ContentBlock';
import { Section } from './Section';

export const BuscaSemantica: React.FC = () => (
  <Section backgroundColor={color.background.secondary}>
    <Container maxWidth="default">
      <ContentBlock
        tag="Ativo"
        title="Seja mais rápido e preciso na busca de imóveis"
        content={[
          'Aprimore a busca do imóvel e tenha maior assertividade, destacando detalhes importantes para seus clientes.',
          'Busque não apenas pelas características básicas, mas também por detalhes visuais, como por exemplo chão de madeira e tipos de azulejos.',
        ]}
        image="/images/busca-semantica.webp"
        imageAlt="Celular com tela de busca semântica de imóveis"
      />
    </Container>
  </Section>
);
